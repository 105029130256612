(function(angular) {
    'use strict';

    angular.module('spCaptcha',[])
    .service('SpCaptcha', ['$rootScope', function($rootScope) {
        var self = this;

        self.countFormSubmits = countFormSubmits;
        self.formSubmitSuccess = formSubmitSuccess;
        self.formSubmitFailed = formSubmitFailed;
        self.getLastCaptchaVerifyHash = getLastCaptchaVerifyHash;

        $rootScope.submitAttempts = $rootScope.submitAttempts || 0;

        /**
         * On init check current attempts number
         * @public
         *
         * @param {Object} controller
         */
        function countFormSubmits(controller) {
            _isShowCaptcha(controller);
        }

        /**
         * Get last captcha verify hash
         * @public
         *
         */
        function getLastCaptchaVerifyHash() {
            if (!$rootScope.$captchas) {
                return null;
            } 
            var captchas = $rootScope.$captchas;
            var captchasNames = Object.keys(captchas);
            return captchas[captchasNames[captchasNames.length - 1]];
        }

        /**
         * Add failed login attempt
         * @public
         *
         * @param {Object} controller
         */
        function formSubmitFailed(controller) {
            $rootScope.submitAttempts++;
            _isShowCaptcha(controller);
        }

        /**
         * Reset login attempts on success
         * @public
         *
         * @param {Object} controller
         */
        function formSubmitSuccess(controller) {
            $rootScope.submitAttempts = 0;
            _isShowCaptcha(controller);
        }

        /**
         * Check is show captcha
         * @private
         *
         * @param {Object} controller
         */
        function _isShowCaptcha(controller) {
            controller.showCaptcha = $rootScope.submitAttempts >= 3;
        }
    }])
    .directive('spCaptcha', [ '$rootScope', '$compile', '$window', 'Config', function ($rootScope, $compile, $window, Config) {
        var template = '<div class="captcha-holder"></div>' + 
        '<div class="sr-only-element" ng-hide="!errorToggle" aria-live="assertive" aria-hidden="false" role="alert">{{expiredErrorMessage}}</div>';

        return {
            restrict: 'E',
            replace: false,
            template: template,
            scope: {
                theme: '@',
                size: '@',
                message: '@',
                isInvalid: '=?'
            },
            controller: ['$scope', '$element', function ($scope, $element) {

                var config = {
                    RECAPTCHA_SITE_KEY: '6LdJo40UAAAAACPSKmyvcYQQnel2dNzGtXFJdEbV',
                    theme: $scope.theme || 'light', // light or dark,
                    size: $scope.size || 'normal', // 'compact' or 'normal',
                    warning: $scope.message || 'Please verify that you are not a robot',
                    language: Config.language && Config.language.culture || 'en'
                };

                var settings = {
                    captchaElementClassName: 'captcha-holder',
                    captchaElementIdPrefix: 'spcap_',
                    warningElementClassName: 'captcha-warning-msg',
                    captchaErrorClassName: 'captcha-error'
                };

                var expiredMessageTranslate = {
                    en: 'Verification challenge expired. Check the checkbox again.',
                    he: 'פג תוקף האימות. עליך לסמן שוב את תיבת הסימון.',
                    ar: 'انتهت صلاحية التحقق. حدّد مربّع الاختيار مرة أخرى.',
                    fr: 'La validation a expiré. Cochez à nouveau la case.',
                    es: 'La verificación ha caducado. Vuelve a marcar la casilla de verificación.',
                    ru: 'Время проверки истекло. Установите флажок и повторите попытку.'
                }

                var outerElement = $element[0],
                    captchaElement = angular.element(outerElement)[0].querySelector('.' + settings.captchaElementClassName),
                    elemUniqueId = settings.captchaElementIdPrefix + Math.floor(Math.random() * 99999999),
                    messageElement;
                init();

                /**
                 * Main init function
                 * @private
                 */
                function init() {
                    //== Captcha can be used inside FORM element only
                    if(!checkIsInsideForm()) {
                        return;
                    }

                    setErrorStyle(false, outerElement);
                    markUnique();
                    appendInput();
                    appendMessage();
                    tryRender();
                    tryInsertScript();
                    setupFormAction();
                }

                /**
                 * Check is this Cordova Application
                 * @private
                 *
                 * @return {Boolean}
                 */
                function isApplication() {
                    return $window.cordova !== undefined;
                }

                /**
                 * Create unique identifier for each captcha element in HTML
                 * @private
                 */
                function markUnique() {
                    captchaElement.setAttribute('id', elemUniqueId);
                }

                /**
                 * Create input for simple form validation
                 * @private
                 */
                function appendInput() {
                    var input = document.createElement('input');
                    input.type = 'hidden';
                    input.required = true;
                    input.id = 'captcha_hidden_input';
                    input.setAttribute('ng-model', '$root.$captchas.' + elemUniqueId);
                    outerElement.appendChild(input);
                    $compile(input)($scope);
                }

                /**
                 *  Warning message displayed below the captcha until it successfully solved
                 * @private
                 */
                function appendMessage() {
                    messageElement = document.createElement('div');
                    messageElement.className = settings.warningElementClassName;
                    messageElement.innerText = '{{::"' + config.warning + '" | translate}}';
                    messageElement.setAttribute('ng-hide', '$root.$captchas.' + elemUniqueId + ' || errorToggle');
                    messageElement.setAttribute('aria-live', 'assertive');
                    messageElement.setAttribute('role', 'alert');
                    outerElement.appendChild(messageElement);
                    $compile(messageElement)($scope);
                }

                /**
                 * Check if directive tag used inside FORM element
                 * @private
                 *
                 * @return {Boolean}
                 */
                function checkIsInsideForm() {
                    var form = angular.element(outerElement)[0].closest('form');
                    if(!form) console.error('Captcha tag inserted outside FORM');
                    return !!form;
                }

                /**
                 * If reCaptcha object already exists - render it, if not store it until callback
                 * @private
                 */
                function tryRender() {
                    if(window.grecaptcha) {
                        captchaRender(captchaElement, config, elemUniqueId);
                    } else {
                        saveThisCaptchaToFirstRender();
                    }
                }

                /**
                 * Element exists in HTML but captcha object doesn't so we save this element's id with it's config
                 * @private
                 */
                function saveThisCaptchaToFirstRender() {
                    window.capthaToRenderElements = window.capthaToRenderElements || [];
                    config.key = elemUniqueId;
                    window.capthaToRenderElements.push(config);
                }

                /**
                 * Insert reCaptcha script and set callback function
                 * @private
                 */
                function tryInsertScript() {

                    //== check if script already set up to prevent multiple script loading
                    if(window.spCaptchaInserted) {
                        return;
                    }
                    window.spCaptchaInserted = true;

                    //== Set callback function that will be called when reCaptcha loaded and initiated
                    window.spCaptchaListener = function() {
                        captchaListener();
                    };

                    //== Create and insert reCaptcha script
                    var script = document.createElement('script');
                    script.async = true;
                    script.defer = true;
                    script.type = 'text/javascript';
                    script.src = 'https://www.google.com/recaptcha/api.js?onload=spCaptchaListener&render=explicit';
                    var firstScript = document.getElementsByTagName('script')[0];
                    firstScript.parentNode.insertBefore(script, firstScript);
                }

                /**
                 * Create default element on $rootScope
                 * @private
                 */
                function setupFormAction() {
                    $rootScope.$captchas = $rootScope.$captchas || {};
                }

                /**
                 * Called by Google when reCaptcha script loaded
                 * @private
                 */
                function captchaListener() {
                    window.grecaptcha = grecaptcha ? grecaptcha : window.grecaptcha;
                    captchaFirstRender();
                    emitEvent(elemUniqueId, null, 'load');
                }

                /**
                 * First render - there can be a multiple captcha elements in HTML that need to be rendered
                 * @private
                 */
                function captchaFirstRender() {
                    if(window.capthaToRenderElements && window.capthaToRenderElements.length) {
                        angular.forEach(window.capthaToRenderElements, function(options) {
                            captchaRender(document.getElementById(options.key), options, options.key)
                        });
                        window.capthaToRenderElements = null;
                    }
                }

                /**
                 * Render single captcha element
                 * @private
                 *
                 * @param {Object} element
                 * @param {Object} options
                 * @param {String} elemUniqueId
                 */
                function captchaRender(element, options, elemUniqueId) {
                    var widgetId = window.grecaptcha.render(element, {
                        'sitekey': options.RECAPTCHA_SITE_KEY,
                        'theme': options.theme,
                        'size': options.size,
                        'hl': options.language,
                        'callback': function(response) {
                            //== executed when the user submits a successful response
                            setCaptchaValidity(elemUniqueId, response);
                            emitEvent(elemUniqueId, widgetId, 'success');

                            $scope.errorToggle = false;
                        },
                        'expired-callback': function() {
                            //== executed when the reCaptcha response expires and the user needs to re-verify
                            setCaptchaValidity(elemUniqueId, '');
                            emitEvent(elemUniqueId, widgetId, 'expired');

                            $scope.expiredErrorMessage = expiredMessageTranslate[config.language];
                            $scope.errorToggle = true;
                        }
                    });
                    setCaptchaWidgetIdToForm(widgetId, element);
                    emitEvent(elemUniqueId, widgetId, 'display');
                }

                /**
                 * Called by Google when reCaptcha solved successfully
                 * @private
                 *
                 * @param {String} uniqueKey
                 * @param {String} response
                 */
                function setCaptchaValidity(uniqueKey, response) {
                    $rootScope.$captchas[uniqueKey] = response;
                    setErrorStyle(!response, document.getElementById(uniqueKey).parentNode)
                }

                /**
                 * Render single captcha element
                 * @private
                 *
                 * @param {String} widgetId
                 * @param {Object} element
                 */
                function setCaptchaWidgetIdToForm(widgetId, element) {
                    var form = angular.element(element)[0].closest('form');
                    form && form.setAttribute('data-captcha-widget-id', widgetId);
                }

                /**
                 * Set/remove error class to outer captcha element
                 * @private
                 *
                 * @param {Boolean} state
                 * @param {Object} outerElement
                 */
                function setErrorStyle(state, outerElement) {
                    state ? outerElement.classList.add(settings.captchaErrorClassName) : outerElement.classList.remove(settings.captchaErrorClassName);
                }

                /**
                 * Fire event
                 * @private
                 *
                 *
                 * @param {String} captchaId
                 * @param {String} widgetId
                 * @param {String} action
                 */
                function emitEvent(captchaId, widgetId, action) {
                    $rootScope.$emit('spCaptcha.event', {
                        captchaId: captchaId,
                        widgetId: widgetId,
                        action: action
                    });
                }

                $scope.$watch('isInvalid', function (value) {
                    if (!!value) {
                        setErrorStyle(true, outerElement);
                    } else {
                        setErrorStyle(false, outerElement);
                    }
                });
            }]
        };
    }]);
})(angular);