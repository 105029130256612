(function (angular) {
    'use script';

    angular.module('spVirtualRepeat', []);
})(angular);(function(angular) {
    'use strict';

    var INVISIBLE_ITEMS = 3;

    angular.module('spVirtualRepeat').directive('spVirtualRepeat', ['$compile', '$timeout', function($compile, $timeout) {
        return {
            scope: true,
            bindToController: {
                itemName: '@spVirtualRepeatItemName'
            },
            controllerAs: 'virtualRepeatCtrl',
            controller: function() {},
            compile: function($element) {
                var _repeatElement = _getRepeatElement($element.contents()).clone();
                $element.html('<div class="sp-virtual-repeat-height"></div><div class="sp-virtual-repeat-items"></div>');
                $element.addClass('sp-virtual-repeat-container');

                function link($scope, $element, $attrs, virtualRepeatCtrl) {
                    var _heightElement = angular.element($element.contents()[0]),
                        _itemsElement = angular.element($element.contents()[1]),
                        _elements = [],
                        _items = [],
                        _firstItemIndex = 0,
                        _itemElementHeight = 0,
                        _lastScrollData,
                        _scrollTimeout;

                    $element.bind('scroll', function(event) {
                        if (_scrollTimeout) {
                            $timeout.cancel(_scrollTimeout);
                        }

                        _scrollTimeout = $timeout(_relocateElements, 200);

                        _relocateElements();
                        $scope.$apply();
                    });

                    $scope.$watchCollection($attrs.spVirtualRepeat, function(items) {
                        _items = items;
                        _resetAll();
                    });

                    $scope.$watch(function() {
                        return _elements.length ? _elements[0].element[0].clientHeight : 0;
                    }, function(height) {
                        if (height && _elements.length) {
                            var elementHeight = _getElementExactHeight(_elements[0].element);
                            if (_itemElementHeight !== elementHeight) {
                                _itemElementHeight = elementHeight;
                                _resetAll();
                            }
                        }
                    });

                    $scope.$watch(function() {
                        return $element[0].clientHeight;
                    }, function(newVal, oldVal) {
                        if (newVal !== oldVal) {
                            _resetAll();
                        }
                    });

                    function _relocateElements() {
                        var scrollData = _getCurrentScrollData(),
                            element;

                        _setElements(scrollData);

                        // move from end to beginning when the first item index decreased
                        while (_firstItemIndex > scrollData.firstItemIndex) {
                            element = _elements.pop();
                            _elements.unshift(element);
                            _itemsElement.prepend(element.element);
                            _firstItemIndex--;
                        }

                        // move beginning to end when the first item index increased
                        while (scrollData.firstItemIndex > _firstItemIndex) {
                            element = _elements.shift();
                            _elements.push(element);
                            _itemsElement.append(element.element);
                            _firstItemIndex++;
                        }

                        _bindElements();
                        _setItemsTop();
                    }

                    function _resetAll() {
                        _heightElement.css('height', (_itemElementHeight * _items.length) + 'px');
                        _setElements();
                        _bindElements();
                    }

                    function _getElementExactHeight(element) {
                        var height = getCurrentStyleProp(element, 'height', true) +
                            getCurrentStyleProp(element, 'margin-top', true) +
                            getCurrentStyleProp(element, 'margin-bottom', true);

                        if (getCurrentStyleProp(element, 'box-sizing') !== 'border-box') {
                            height += getCurrentStyleProp(element, 'padding-top', true) +
                                getCurrentStyleProp(element, 'padding-bottom', true) +
                                getCurrentStyleProp(element, 'border-top-width', true) +
                                getCurrentStyleProp(element, 'border-bottom-width', true);
                        }

                        return height;
                    }

                    function getCurrentStyleProp(element, prop, isNumber) {
                        element = angular.element(element)[0];
                        var res = '';
                        if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
                            res = window.getComputedStyle(element).getPropertyValue(prop);
                        } else if (element.currentStyle && element.currentStyle[prop]) {
                            res = element.currentStyle[prop];
                        }
                        if (isNumber && res) {
                            var resNumber = Number(res.replace('px', ''));
                            if (angular.isNumber(resNumber) && !isNaN(resNumber)) {
                                res = resNumber;
                            }
                        }
                        return res;
                    }

                    function _setElements(scrollData) {
                        if (!_items.length) {
                            return;
                        }

                        if (!_elements.length) {
                            $element[0].scrollTop = 0;
                            _addElement();
                            return _setElements();
                        }

                        if (!_itemElementHeight) {
                            return;
                        }

                        scrollData = scrollData || _getCurrentScrollData();

                        if (_elements.length > scrollData.totalElements) {
                            _removeElements(scrollData.totalElements, scrollData.scrollTop, scrollData.aboveElements);
                        } else if (_elements.length < scrollData.totalElements) {
                            _addElements(scrollData);
                        }

                        _setItemsTop();
                        _lastScrollData = scrollData;
                    }

                    function _getCurrentScrollData() {
                        var aboveScrollData = _getAboveScrollData(),
                            containerHeight = getCurrentStyleProp($element, 'height', true),
                            shownElements = _getShownElements(containerHeight, aboveScrollData.scrollTop),
                            belowElements = _getBelowElements(aboveScrollData.scrollTop, shownElements),
                            totalElements = shownElements + aboveScrollData.aboveElements + belowElements;

                        var totalLength = totalElements + aboveScrollData.firstItemIndex;
                        if (totalLength > _items.length) {
                            belowElements -= totalLength - _items.length;

                            if (belowElements < 0) {
                                shownElements += belowElements;
                                belowElements = 0;
                            }

                            totalElements = shownElements + aboveScrollData.aboveElements + belowElements;
                        }

                        return {
                            height: containerHeight,
                            scrollTop: aboveScrollData.scrollTop,
                            firstItemIndex: aboveScrollData.firstItemIndex,
                            aboveElements: aboveScrollData.aboveElements,
                            belowElements: belowElements,
                            shownElements: shownElements,
                            totalElements: totalElements
                        };
                    }

                    function _getShownElements(containerHeight, scrollTop) {
                        var scrollBottom = scrollTop + containerHeight,
                            firstShownTop = scrollTop - scrollTop % _itemElementHeight,
                            lastShownBottom = firstShownTop;
                        while (lastShownBottom < scrollBottom) {
                            lastShownBottom += _itemElementHeight;
                        }
                        return Math.round((lastShownBottom - firstShownTop) / _itemElementHeight);
                    }

                    function _getBelowElements(scrollTop, shownElements) {
                        var lastShownBottom = (scrollTop - scrollTop % _itemElementHeight) + (shownElements * _itemElementHeight);
                        var belowHeight = Math.max($element[0].scrollHeight - lastShownBottom, 0);
                        return Math.min(Math.floor(belowHeight / _itemElementHeight), 3);
                    }

                    function _getAboveScrollData() {
                        var scrollTop = $element[0].scrollTop,
                            aboveItemsSpace = Math.floor(scrollTop / _itemElementHeight),
                            aboveElements = Math.min(aboveItemsSpace, 3);

                        return {
                            scrollTop: scrollTop,
                            firstItemIndex: aboveItemsSpace - aboveElements,
                            aboveElements: aboveElements
                        };
                    }

                    function _removeElements(shouldBeShown, scrollTop, shouldBeAbove) {
                        var actualAbove = Math.floor((scrollTop - (_firstItemIndex * _itemElementHeight)) / _itemElementHeight),
                            i;
                        for (i = 0; i < actualAbove - shouldBeAbove; i++) {
                            _removeElement(0);
                            _firstItemIndex++;
                        }

                        while (_elements.length > shouldBeShown) {
                            _removeElement(_elements.length - 1);
                        }
                    }

                    function _setItemsTop() {
                        _itemsElement.css('top', (_firstItemIndex * _itemElementHeight) + 'px');
                    }

                    function _addElements(scrollData) {
                        var atTheBeginning = scrollData.aboveElements - (_lastScrollData ? _lastScrollData.aboveElements : 0),
                            atTheEnd = scrollData.totalElements - _elements.length - atTheBeginning,
                            i;

                        for (i = 0; i < atTheBeginning; i++) {
                            _addElement(0);
                            _firstItemIndex--;
                        }

                        for (i = 0; i < atTheEnd; i++) {
                            _addElement(_elements.length - 1);
                        }
                    }

                    function _removeElement(index) {
                        _elements[index].element.remove();
                        _elements[index].scope.$destroy();
                        _elements.splice(index, 1);
                    }

                    function _bindElements() {
                        angular.forEach(_elements, function(element, index) {
                            element.scope[virtualRepeatCtrl.itemName] = _items[_firstItemIndex + index];
                        });
                    }

                    function _addElement(atIndex) {
                        var addedElement = {
                            element: _repeatElement.clone(),
                            scope: $scope.$new()
                        };

                        _elements.splice(atIndex, 0, addedElement);
                        $compile(addedElement.element)(addedElement.scope);
                        if (_elements[atIndex - 1]) {
                            _elements[atIndex - 1].element.after(addedElement.element);
                        } else {
                            _itemsElement.prepend(addedElement.element);
                        }
                        return addedElement;
                    }
                }

                function _getRepeatElement(elements) {
                    var repeatElement;
                    angular.forEach(elements, function(element) {
                        if (element.tagName) {
                            if (repeatElement) {
                                throw new Error('sp-virtual-repeat demands for a single root element to be transcluded');
                            }

                            repeatElement = element;
                        }
                    });
                    return angular.element(repeatElement);
                }

                return link;
            }
        };
    }]);
})(angular);