angular.module('sp-autocomplete', []);
(function() {
	'use strict';

	var COUNTER = 0;

	angular.module('sp-autocomplete').directive('spAutocomplete', [
		'$location', '$rootScope', '$timeout', '$q', 'Util',
		function($location, $rootScope, $timeout, $q, util) {
			return {
				restrict: 'E',
				replace: true,
				template: function(element, attrs) {
					if (attrs.mobile) {
						return '' +
							'<span class="auto-complete" ng-show="autoCompleteCtrl.shown" role="listbox" ' +
							'id="{{autoCompleteCtrl.elementId}}" aria-label="{{ariaLabel}}">' +
							'   <span class="option default-text-align" ng-class="{\'current\': autoCompleteCtrl.currentIndex == $index, \'filter-option\': !!option.filter}" ' +
							'         ng-repeat="option in autoCompleteCtrl.options" ng-click="autoCompleteCtrl.chooseOption($index)" role="option"' +
							'         aria-selected="{{autoCompleteCtrl.currentIndex == $index}}">' +
							'       <span ng-if="!!option.filter" class="filter-option-content" ng-include="filtersTemplateUrl"></span>' +
							'       <sp-search-highlight ng-if="!option.filter" query="model" option="option.value"></sp-search-highlight>' +
							'       <md-button ng-if="!option.filter" class="add-query" aria-label="add-query" ng-click="autoCompleteCtrl.changeQuery($event, $index);" >' +
							'           <span class="for-vertical-align"></span>' +
							'           <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTMyIDc5LjE1OTI4NCwgMjAxNi8wNC8xOS0xMzoxMzo0MCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTUuNSAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0MyNTEyQkQ2ODM4MTFFNkI5NUM4QzNFNjA5QkE2QkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0MyNTEyQkU2ODM4MTFFNkI5NUM4QzNFNjA5QkE2QkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQzI1MTJCQjY4MzgxMUU2Qjk1QzhDM0U2MDlCQTZCQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQzI1MTJCQzY4MzgxMUU2Qjk1QzhDM0U2MDlCQTZCQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pixm0cgAAAECSURBVHjaYmSAguXLl7MDKU4G8sCnyMjIf+iCjFCDs4HUFAbywX8gVgVacBdZkAnqYkoMhjnyALogEwVBgQ6mowuw4FD4C4iXAHExkQb/AgbJN2IM/wvEbEAcA42oQnK9woRF7D2SBVnAOOmnpuGOQPySGhZgM/wJEJtTwwJshjMAw5kqFjDhkqCGBUz4JCm1gImQAkosYCLGBeRawERs+JFjARMpsU+qBUykpl1SLAAZ/glaHiMXWlSxgAlag6hCc2YNttKNXAsYKS3EgYbJAKmTQCwOxMxQn08DlaYUG47Hgl6qGI7Dgp9M1DIcKQ6eQBNIAQO1AdAHbEAsCmIDBBgAwj2NSLKMlOEAAAAASUVORK5CYII%3D">' +
							'       </md-button>' +
							'   </span>' +
							'</span>';
					}

					return '' +
						'<span class="auto-complete" ng-show="autoCompleteCtrl.shown"' +
						'      id="{{autoCompleteCtrl.elementId}}"' +
						'      role="listbox" aria-label="{{ariaLabel}}">' +
						'   <span class="option default-text-align" ng-class="{\'current\': autoCompleteCtrl.currentIndex == $index, \'filter-option\': !!option.filter}" ' +
						'         ng-repeat="option in autoCompleteCtrl.options" ' +
						'         ng-click="autoCompleteCtrl.chooseOption($index)"' +
						'         ng-mouseenter="autoCompleteCtrl.currentIndex = $index;" ' +
						'         tabindex="-1" role="option"' +
						'         id="{{autoCompleteCtrl.elementId}}_option_{{$index}}"' +
						'         aria-selected="{{autoCompleteCtrl.currentIndex == $index}}">' +
						'       <span ng-if="!!option.filter" class="filter-option-content" ng-include="filtersTemplateUrl"></span>' +
						'       <sp-search-highlight ng-if="!option.filter" query="model" option="option.value"></sp-search-highlight>' +
						'   </span>' +
						'</span>';
				},
				scope: {
					model: '=spModel',
					optionModel: '=?spOptionModel',
					getOptions: '&spGetOptions',
					choose: '&spChoose',
					threshold: '=?',
					hideIfEmpty: '=?',
					filters: '&?spFilters',
					filtersTemplateUrl: '@?spFiltersTemplateUrl',
					value: '@?spValue',
					identifier: '@?spIdentifier',
					requireMatch: '<?spRequireMatch',
					reloadOptionsOn: '@?spReloadOptionsOn',
					showOnFocus: '<?spShowOnFocus',
					ariaLabel: '@?spAriaLabel',
					onShow: '&?',
					onHide: '&?'
				},
				controllerAs: 'autoCompleteCtrl',
				controller: [
					'$scope', '$element', '$attrs',
					function($scope, $element, $attrs) {
						var autoCompleteCtrl = this,
							_parentElement = angular.element($element[0].parentNode || $element[0].parentElement),
							_body = document.querySelector('body'),
							_inputElement = angular.element(null),
							_inputNgModelController,
							_reloadAutoComplete,
							_showOptionsWatcher,
							_chosenOption;

						autoCompleteCtrl.chooseOption = chooseOption;
						autoCompleteCtrl.changeQuery = changeQuery;
						autoCompleteCtrl.shown = false;
						autoCompleteCtrl.elementId = 'sp_autocomplete_' + COUNTER++;

						var _inputAttrs = {
							'role': 'combobox',
							'aria-autocomplete': 'list',
							'aria-expanded': 'false',
							'aria-haspopup': 'listbox',
							'aria-owns': autoCompleteCtrl.elementId
						};

						if (angular.isUndefined($scope.threshold)) {
							$scope.threshold = 300;
						}

						_parentElement.addClass('auto-complete-wrapper');

						function chooseOption(index) {
							autoCompleteCtrl.chooseOptionClick = true;
							_setRequireMatchValidation(true);
							_chosenOption = $scope.optionModel = null;

							var option, filter;
							if (index > -1) {
								option = autoCompleteCtrl.options[index];
							} else if (autoCompleteCtrl.options.length === 1) {
								index = 0;
								option = autoCompleteCtrl.options[0];
							}

							if (option) {
								$scope.model = option.value;
								_chosenOption = $scope.optionModel = option;
								filter = option.filter;
								autoCompleteCtrl.currentIndex = index;
								_setInputAttr('aria-activedescendant', (autoCompleteCtrl.elementId + '_option_' + index));
							}

							$timeout(function() {
								$scope.choose(option && { $event: { filter: filter, option: option, index: index } });
								_setOptions();
								_inputElement && _inputElement[0].focus();
								$timeout(function() {
									autoCompleteCtrl.chooseOptionClick = false;
									autoCompleteCtrl.postChooseOptionClick = true;
								}, 0);
							}, 100);
						}

						function changeQuery(event, index) {
							var option = autoCompleteCtrl.options[index];
							$scope.model = option.value;

							event.stopPropagation();
							event.preventDefault();
							event.stopImmediatePropagation();

							_reloadOptions();
						}

						function _reloadOptions() {
							if (_reloadAutoComplete) {
								$timeout.cancel(_reloadAutoComplete);
							}
							_setRequireMatchValidation(true);
							autoCompleteCtrl.postChooseOptionClick = false;

							var currentChosenOption = _chosenOption;
							_chosenOption = null;

							_reloadAutoComplete = $timeout(function() {
								var filtersDetails = {
									model: $scope.model,
									filters: $scope.filters && $scope.filters()
								};
								if (filtersDetails.filters && !angular.isArray(filtersDetails.filters)) {
									filtersDetails.filters = [filtersDetails.filters];
								}

								$q.resolve().then(function() {
									return $scope.getOptions();
								}).then(function(options) {
									_setOptions(options || [], filtersDetails, currentChosenOption);
								}).catch(function() {
									_setOptions([], filtersDetails, currentChosenOption);
								});
							}, $scope.threshold);
						}

						function _setOptions(options, filtersDetails, prevChosenOption) {
							if (options) {
								var newOptions = [];
								if (filtersDetails.filters && filtersDetails.filters.length && filtersDetails.model && $scope.filtersTemplateUrl) {
									angular.forEach(filtersDetails.filters, function(filter) {
										newOptions.push({
											value: filtersDetails.model,
											filter: filter
										});
									});
								}
								var matchingOptions = [];
								angular.forEach(options, function(option) {
									var newOption = _parseOption(option);
									newOptions.push(newOption);
									if (newOption.value === $scope.model) {
										matchingOptions.push(newOption);
									}
								});
								if (matchingOptions.length === 1) {
									_chosenOption = matchingOptions[0];
								} else if (prevChosenOption) {
									// retain chosen option when has more than one new options and identifier matches
									var option = matchingOptions.find(function(option) {
										return option.identifier === prevChosenOption.identifier;
									});
									if (option) {
										_chosenOption = option;
									}
								}
								$scope.optionModel = _chosenOption;
								autoCompleteCtrl.options = newOptions;
							} else {
								autoCompleteCtrl.options = [];
							}

							_setRequireMatchValidation(!$scope.model || !!_chosenOption);
							autoCompleteCtrl.currentIndex = -1;
							_inputElement && _inputElement[0].removeAttribute('aria-activedescendant');
							$rootScope.$emit('autoComplete.set');

							_callSetIsOutOfScreen();
						}

						function _parseOption(option) {
							if (!option) {
								return option;
							}

							var value = $scope.$eval($scope.value || 'option', { option: option });
							return angular.extend({}, option, {
								value: value,
								identifier: $scope.identifier && $scope.$eval($scope.identifier, { option: option }) || value
							});
						}

						function _setRequireMatchValidation(isValid) {
							if ($scope.requireMatch && _inputNgModelController) {
								_inputNgModelController.$setValidity('spAutoCompleteRequireMatch', isValid);
							}
						}

						function _isElementFixed(element) {
							element = angular.element(element)[0];
							var res = '';
							if (window.getComputedStyle && angular.isFunction(window.getComputedStyle)) {
								res = window.getComputedStyle(element).getPropertyValue('position');
							} else if (element.currentStyle && element.currentStyle.position) {
								res = element.currentStyle.position;
							}
							return res === 'fixed';
						}

						function _callSetIsOutOfScreen() {
							$timeout(_setIsOutOfScreen, 0);
							$timeout(_setIsOutOfScreen, 500);
						}

						function _setIsOutOfScreen() {
							var currentElement = _parentElement[0],
								top = 0;
							do {
								top += currentElement.offsetTop;
								currentElement = currentElement.offsetParent;
							} while (!!currentElement && !_isElementFixed(currentElement));

							var bottom = top + _parentElement[0].offsetHeight + $element.prop('offsetHeight');

							if (bottom > _body.offsetHeight) {
								$element.addClass('out-of-screen');
							} else {
								$element.removeClass('out-of-screen');
							}
						}

						function _apply() {
							$scope.$applyAsync();
						}

						function _inputKeyDown(event) {
							if (event.which === 13) {
								event.preventDefault();
								event.stopImmediatePropagation();
								chooseOption(autoCompleteCtrl.currentIndex);
								return _apply();
							}

							var toAdd = 0;
							switch (event.which) {
								case 40:
									toAdd = 1;
									break;
								case 38:
									toAdd = -1;
									break;
							}
							if (toAdd) {
								autoCompleteCtrl.currentIndex += toAdd;
								if (autoCompleteCtrl.currentIndex < -1) {
									autoCompleteCtrl.currentIndex = autoCompleteCtrl.options.length - 1;
								}
								if (!autoCompleteCtrl.options || autoCompleteCtrl.currentIndex >= autoCompleteCtrl.options.length) {
									autoCompleteCtrl.currentIndex = -1;
								}

								_setInputAttr('aria-activedescendant', autoCompleteCtrl.elementId + '_option_' + autoCompleteCtrl.currentIndex);
								event.preventDefault();
								event.stopImmediatePropagation();
								_apply();
							}
						}

						function _inputFocus() {
							autoCompleteCtrl.inFocus = (autoCompleteCtrl.inFocus || 0) + 1;
							if (!autoCompleteCtrl.options || $scope.showOnFocus && !autoCompleteCtrl.options.length && $scope.model) {
								_reloadOptions();
							}
							_apply();
							_callSetIsOutOfScreen();
						}

						function _inputBlur() {
							// ignore blur when no focus is active
							if (!autoCompleteCtrl.inFocus || autoCompleteCtrl.inFocus < 0) {
								return;
							}

							$timeout(function() {
								if (autoCompleteCtrl.inFocus && autoCompleteCtrl.inFocus > 0) {
									autoCompleteCtrl.inFocus--;
								}
							}, 200);
						}

						function _bindInput() {
							_inputElement.bind('keydown', _inputKeyDown);
							_inputElement.bind('focus', _inputFocus);
							_inputElement.bind('blur', _inputBlur);

							angular.forEach(_inputAttrs, function(value, key) {
								_setInputAttr(key, value);
							});
						}

						function _setInputAttr(key, value) {
							_inputElement && _inputElement[0].setAttribute(key, value);
						}

						var watcher = $scope.$watch(function() {
							return _parentElement[0].querySelector('input[ng-model="' + $attrs.spModel + '"]');
						}, function(newValue) {
							if (!newValue) return;

							watcher();
							_inputElement = angular.element(newValue);
							_inputNgModelController = _inputElement.data('$ngModelController');
							_bindInput();
						});

						$scope.$watch('model', function(newVal) {
							if (_chosenOption && _chosenOption.value === newVal) {
								return;
							}

							_reloadOptions();
						});
						$scope.$watch('optionModel', function(newVal) {
							if (_chosenOption !== newVal) {
								_chosenOption = $scope.optionModel = _parseOption(newVal);
								_setRequireMatchValidation(!$scope.model || !!_chosenOption);
							}
						});

						var _reloadListener;

						$scope.$watch('reloadOptionsOn', function(newVal) {
							_reloadListener && _reloadListener();

							if (newVal) {
								_reloadListener = $rootScope.$on(newVal, function() {
									_reloadOptions();
								});
							}
						});

						$scope.$watch('requireMatch', function() {
							if (!$scope.requireMatch && _inputNgModelController) {
								_inputNgModelController.$setValidity('spAutoCompleteRequireMatch', true);
							}
						});

						$scope.$on('$destroy', function() {
							_reloadListener && _reloadListener();
						});

						if ($attrs.mobile) {
							_showOptionsWatcher = 'autoCompleteCtrl.options.length && autoCompleteCtrl.inFocus && (!hideIfEmpty || model.length) && !autoCompleteCtrl.postChooseOptionClick';
						} else {
							_showOptionsWatcher = 'autoCompleteCtrl.options.length && (autoCompleteCtrl.inFocus || autoCompleteCtrl.chooseOptionClick) && (!hideIfEmpty || model.length) && !autoCompleteCtrl.postChooseOptionClick';
						}

						$scope.$watch(_showOptionsWatcher, function(newVal) {
							_setShown(!!newVal);
						});

						function _setShown(value) {
							var prevValue = autoCompleteCtrl.shown;
							autoCompleteCtrl.shown = value;
							_setInputAttr('aria-expanded', autoCompleteCtrl.shown);
							if (autoCompleteCtrl.shown) {
								_parentElement.addClass('auto-complete-options-shown');
							} else {
								_parentElement.removeClass('auto-complete-options-shown');
							}

							if (prevValue !== autoCompleteCtrl.shown) {
								if (autoCompleteCtrl.shown && $scope.onShow) {
									$scope.onShow();
								}
								if (!autoCompleteCtrl.shown && $scope.onHide) {
									$scope.onHide();
								}
							}
						}

						function _onResize() {
							_callSetIsOutOfScreen();
						}

						angular.element(window).bind('resize', _onResize);

						util.destroyListeners($scope, function() {
							_setShown(false);
							angular.element(_inputElement).unbind('keydown', _inputKeyDown);
							angular.element(_inputElement).unbind('focus', _inputFocus);
							angular.element(_inputElement).unbind('blur', _inputBlur);
							angular.element(window).unbind('resize', _onResize);
						});
					}
				]
			};
		}]);
}());

(function () {
    'use strict';

    angular.module('sp-autocomplete').directive('spSearchHighlight', [
        '$rootScope', 'Util',
        function ($rootScope, util) {
            return {
                restrict: 'E',
                scope: {
                    query: '=',
                    option: '='
                },
                link: function ($scope, $element) {
                    function setHighlight() {
                        if (!$scope.option) return;
                        if (!$scope.query) {
                            $element.html($scope.option);
                            return;
                        }

                        $element.html($scope.option.replace(new RegExp($scope.query.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'gi'), '<span class="sp-search-highlight">$&</span>'));
                    }

                    setHighlight();

                    util.destroyListeners($scope, $rootScope.$on('autoComplete.set', setHighlight));
                }
            };
        }]);

}());