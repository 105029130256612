(function(angular) {
    'use strict';

    angular.module('spSeparateInputValue', []);
})(angular);(function (angular) {
    'use strict';

    angular.module('spSeparateInputValue').directive('spSeparateInputValue', [function () {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                modelLength: '<',
                inputLength: '<',
                spModel: '=',
                inputType: '@?'
            },
            template: '' +
                '<input ng-repeat="input in separateInputCtrl.inputs" ' +
                '   ng-change="separateInputCtrl.inputChange(input)"' +
                '   ng-focus="separateInputCtrl.inputFocus(input)"' +
                '   ng-model="input.value" ng-attr-type="{{separateInputCtrl.inputType || \'text\'}}"' +
                '   ng-attr-tabindex="{{$index <= separateInputCtrl.lastValueIndex ? 0 : -1}}"/>',
            controllerAs: 'separateInputCtrl',
            controller: ['$scope', '$element', function($scope, $element) {
                var separateInputCtrl = this,
                    _internalChange = false;

                separateInputCtrl.inputChange = inputChange;
                separateInputCtrl.inputFocus = inputFocus;
                separateInputCtrl.lastValueIndex = 0;

                $scope.$watch(function() {
                    return separateInputCtrl.modelLength + '-' + separateInputCtrl.inputLength;
                }, _init);

                $scope.$watch('separateInputCtrl.spModel', function(val, oldVal) {
                    if (_internalChange) {
                        _internalChange = false;
                        return;
                    }

                    if (val || oldVal) {
                        _init();
                        _focusInput();
                    }
                });

                /**
                 * Init the directive inputs and values
                 * @private
                 */
                function _init() {
                    separateInputCtrl.inputs = [];

                    var modelValue = separateInputCtrl.spModel || '',
                        inputsLength = Math.ceil(separateInputCtrl.modelLength / separateInputCtrl.inputLength);
                    for (var i = 0; i < inputsLength; i++) {
                        separateInputCtrl.inputs.push({
                            value: modelValue.substring(0, separateInputCtrl.inputLength)
                        });
                        modelValue = modelValue.substring(separateInputCtrl.inputLength);
                    }

                    separateInputCtrl.lastValueIndex = _getLastValueInput();
                }

                /**
                 * When an input value changes
                 * @public
                 *
                 * @param {Object} input
                 */
                function inputChange(input) {
                    _setModel();
                    _rearrangeInputs(input);
                }

                /**
                 * @typedef {Object} SpSeparateInput
                 *
                 * @property {number} value
                 */

                /**
                 * When an input get focused
                 * @public
                 *
                 * @param {SpSeparateInput} input
                 */
                function inputFocus(input) {
                    var index = separateInputCtrl.inputs.indexOf(input);
                    if (index > separateInputCtrl.lastValueIndex) {
                        _focusInput(separateInputCtrl.lastValueIndex, separateInputCtrl.inputLength);
                    }
                }

                /**
                 * Rearranges the inputs values in a continuous fashion according to the length limit
                 * @private
                 *
                 * @param {SpSeparateInput} input
                 */
                function _rearrangeInputs(input) {
                    var index = separateInputCtrl.inputs.indexOf(input),
                        selectionPosition = _getInputElement(index).selectionStart;

                    // when the current length is less than it was, i.e. a character was removed
                    if (input.prevLength > (input.value ? input.value.length : 0)) {
                        _subtractNextInputs(input, index);
                    }
                    _pushNextInputs(input, index);

                    input.prevLength = input.value ? input.value.length : 0;

                    separateInputCtrl.lastValueIndex = _getLastValueInput();
                    if (index > separateInputCtrl.lastValueIndex) {
                        _focusInput(separateInputCtrl.lastValueIndex);
                    } else if (selectionPosition > separateInputCtrl.inputLength && index < (separateInputCtrl.inputs.length - 1)) {
                        var focusForward = Math.floor(selectionPosition / separateInputCtrl.inputLength),
                            nextSelectionPosition = selectionPosition - (focusForward * separateInputCtrl.inputLength);
                        if (nextSelectionPosition === 0) {
                            focusForward--;
                            nextSelectionPosition = separateInputCtrl.inputLength;
                        }
                        _focusInput(index + focusForward, nextSelectionPosition);
                    } else {
                        _setSelectionPosition(index, selectionPosition);
                    }
                }

                /**
                 * Returns the index of the last input which contains a value
                 * @private
                 *
                 * @returns {number}
                 */
                function _getLastValueInput() {
                    for (var i = (separateInputCtrl.inputs.length - 1); i >= 0; i--) {
                        if (separateInputCtrl.inputs[i].value && separateInputCtrl.inputs[i].value.length) {
                            return i;
                        }
                    }

                    return 0;
                }

                /**
                 * Subtracts values from next inputs when an input is missing some length
                 * @private
                 *
                 * @param {SpSeparateInput} input
                 * @param {number} inputIndex
                 */
                function _subtractNextInputs(input, inputIndex) {
                    if (input.value && input.value.length >= separateInputCtrl.inputLength) {
                        return;
                    }

                    var toSubtract = separateInputCtrl.inputLength - (input.value ? input.value.length : 0),
                        subtractedValue = '';
                    for (var i = (separateInputCtrl.inputs.length - 1); i > inputIndex; i--) {
                        separateInputCtrl.inputs[i].value = (separateInputCtrl.inputs[i].value || '') + subtractedValue;
                        subtractedValue = separateInputCtrl.inputs[i].value.substring(0, toSubtract);
                        separateInputCtrl.inputs[i].value = separateInputCtrl.inputs[i].value.substring(toSubtract);
                    }
                    input.value = (input.value || '') + subtractedValue;
                }

                /**
                 * Pushes value from the given input to the next ones when its value exceeds the limit
                 * @private
                 *
                 * @param {SpSeparateInput} input
                 * @param {number} inputIndex
                 */
                function _pushNextInputs(input, inputIndex) {
                    if (!input.value || input.value.length <= separateInputCtrl.inputLength) {
                        return;
                    }

                    var pushValue = '';
                    for (var i = inputIndex; i < separateInputCtrl.inputs.length; i++) {
                        separateInputCtrl.inputs[i].value = pushValue + (separateInputCtrl.inputs[i].value || '');
                        pushValue = separateInputCtrl.inputs[i].value.substring(separateInputCtrl.inputLength);
                        separateInputCtrl.inputs[i].value = separateInputCtrl.inputs[i].value.substring(0, separateInputCtrl.inputLength);
                    }
                }

                /**
                 * Joins the inputs values into a single string, ans sets it into the model
                 * @private
                 */
                function _setModel() {
                    var values = [];
                    angular.forEach(separateInputCtrl.inputs, function(input) {
                        values.push(input.value || '');
                    });

                    var newVal = values.join('').substring(0, separateInputCtrl.modelLength);
                    if (newVal !== separateInputCtrl.spModel) {
                        _internalChange = true;
                        separateInputCtrl.spModel = newVal;
                    }
                }

                /**
                 * Returns the dom element of the given input index
                 * @private
                 *
                 * @param {number} index
                 *
                 * @returns {Element}
                 */
                function _getInputElement(index) {
                    return $element.children()[index];
                }

                /**
                 * Focuses the input element ans sets the selection position at the given position
                 * @private
                 *
                 * @param {number} index
                 * @param {number} [position]
                 */
                function _focusInput(index, position) {
                    if (index === undefined) {
                        index = separateInputCtrl.lastValueIndex;
                    }

                    index = _checkIndex(index);
                    var element = _getInputElement(index);
                    element.focus();

                    if (position !== undefined) {
                        _setSelectionPosition(element, position);
                    }
                }

                /**
                 * Set the input element selection position at the given position
                 * @private
                 *
                 * @param {number|Element} indexOrElement
                 * @param {number} position
                 */
                function _setSelectionPosition(indexOrElement, position) {
                    if (typeof(indexOrElement) === 'number') {
                        indexOrElement = _checkIndex(indexOrElement);
                        indexOrElement = _getInputElement(indexOrElement);
                    }

                    setTimeout(function() {
                        indexOrElement.selectionStart = position || 0;
                        indexOrElement.selectionEnd = position || 0;
                    }, 50);
                }

                /**
                 * Check the given index and fix it when it exceeds the length or under 0
                 * @private
                 *
                 * @param {number} index
                 *
                 * @returns {number}
                 */
                function _checkIndex(index) {
                    if (index < 0) {
                        return 0;
                    }
                    if (index >= separateInputCtrl.inputs.length) {
                        return separateInputCtrl.inputs.length - 1;
                    }

                    return index;
                }
            }]
        };
    }]);

})(angular);