(function(angular) {
    'use strict';

    angular.module('spItemsGrid', []);
})(angular);(function (angular) {
    'use strict';

    angular.module('spItemsGrid').directive('spItemsGrid', ['$timeout', function($timeout) {
        var _resizeListeners = [],
            _bodyResizeInterval;

        function _addResizeListener(listener) {
            if (!listener) {
                return;
            }

            if (!_resizeListeners.length) {
                _bindResize();
            }

            _resizeListeners.push(listener);
        }

        function _removeResizeListener(listener) {
            var atIndex = _resizeListeners.indexOf(listener);
            if (atIndex > -1) {
                _resizeListeners.splice(atIndex, 1);
            }

            if (!_resizeListeners.length) {
                _unbindResize();
            }
        }

        function _fireResize() {
            for (var i = 0; i < _resizeListeners.length; i++) {
                _resizeListeners[i]();
            }
        }

        function _bindResize() {
            angular.element(window).bind('resize', _fireResize);

            if (!_bodyResizeInterval) {
                var prevBodyWidth;
                _bodyResizeInterval = setInterval(function() {
                    var bodyWidth = document.body.clientWidth;
                    if (!prevBodyWidth) {
                        prevBodyWidth = bodyWidth;
                        return;
                    }

                    if (bodyWidth !== prevBodyWidth) {
                        _fireResize();
                    }
                    prevBodyWidth = bodyWidth;
                }, 1000);
            }
        }

        function _unbindResize() {
            angular.element(window).unbind('resize', _fireResize);

            if (_bodyResizeInterval) {
                clearInterval(_bodyResizeInterval);
                _bodyResizeInterval = undefined;
            }
        }

        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                items: '<',
                itemWidth: '<'
            },
            controllerAs: 'itemsGridCtrl',
            controller: ['$scope', '$element', function($scope, $element) {
                var itemsGridCtrl = this,
                    _gridWidth,
                    _resizeTimeout;

                _setGridWidth();

                $scope.$watch('itemsGridCtrl.items.length', function() {
                    _setWidths();
                });

                _addResizeListener(_resizeListener);
                $scope.$on('$destroy', function() {
                    _removeResizeListener(_resizeListener);
                });

                function _resizeListener() {
                    if (_resizeTimeout) {
                        $timeout.cancel(_resizeTimeout);
                    }
                    _resizeTimeout = $timeout(function() {
                        _setGridWidth();
                        _setWidths();
                    }, 50);
                }

                function _setGridWidth() {
                    _gridWidth = $element.prop('clientWidth');
                }

                function _setWidths() {
                    if (!itemsGridCtrl.itemWidth) {
                        console.error('Please provide item-width on a sp-items-grid');
                    }

                    var inRow = Math.floor(_gridWidth / itemsGridCtrl.itemWidth),
                        itemWidth = 100 / inRow;

                    if (itemWidth === itemsGridCtrl.width) {
                        return;
                    }

                    itemsGridCtrl.width = 100 / inRow;

                    // distribute double items randomly
                    itemsGridCtrl.multiply = {};
                    var rows = Math.ceil(itemsGridCtrl.items.length / inRow),
                        toDouble = (rows * inRow) - itemsGridCtrl.items.length,
                        indices = _getIndices(),
                        doubledIndices = [],
                        i;
                    for (i = 0; i < toDouble && indices.length; i++) {
                        var indexToDouble = indices.splice(Math.floor(Math.random() * indices.length), 1)[0];
                        itemsGridCtrl.multiply[indexToDouble] = 2;
                        doubledIndices.push(indexToDouble);
                    }

                    // make sure a doubled item is not breaking lines
                    for (i = 0; i < doubledIndices.length; i++) {
                        var replacedWith = _validateAndReplaceDoubledIndex(doubledIndices[i], inRow);
                        if (replacedWith !== undefined) {
                            _pushAtCorrectSort(replacedWith, doubledIndices);
                        }
                    }
                }

                // instead of array.keys() which is not fully supported
                function _getIndices() {
                    var indices = [];
                    for (var i = 0; i < itemsGridCtrl.items.length; i++) {
                        indices.push(i);
                    }
                    return indices;
                }

                function _pushAtCorrectSort(num, numsArray) {
                    var atIndex = numsArray.length;
                    for (var  i = 0; i < numsArray.length; i++) {
                        if (numsArray[i] > num) {
                            atIndex = i;
                            break;
                        }
                    }
                    numsArray.splice(atIndex, 0, num);
                }

                function _validateAndReplaceDoubledIndex(index, inRow) {
                    var isEndOfRow = _isIndexOnEndOfRow(index, inRow);

                    // when the actual spot is at the end of a row
                    if (isEndOfRow) {
                        // this spot cannot be doubled
                        delete itemsGridCtrl.multiply[index];

                        // find the nearest available spot to the bad one, and make it double instead
                        var replaceWith = _findNearestNotDoubleItem(index, inRow);
                        if (replaceWith !== undefined) {
                            itemsGridCtrl.multiply[replaceWith] = 2;

                            // when the replacing spot is ahead,
                            if (replaceWith > index) {
                                return replaceWith;
                            }
                        }
                    }
                }

                function _findNearestNotDoubleItem(index, inRow) {
                    for (var distance = 1; (index + distance) < itemsGridCtrl.items.length || (index - distance) >= 0; distance++) {
                        var minusDistance = index - distance;
                        if (minusDistance >= 0 && !itemsGridCtrl.multiply[minusDistance] && !_isIndexOnEndOfRow(minusDistance, inRow)) {
                            return minusDistance;
                        }
                        var plusDistance = index + distance;
                        if (plusDistance < itemsGridCtrl.items.length && !itemsGridCtrl.multiply[plusDistance]) {
                            return plusDistance;
                        }
                    }
                }

                function _isIndexOnEndOfRow(index, inRow) {
                    var doubledBefore = 0;
                    for (var i = 0; i < index; i++) {
                        doubledBefore += itemsGridCtrl.multiply[i] ? 1 : 0;
                    }

                    return ((index + doubledBefore) % inRow) === (inRow - 1);
                }
            }],
            template: function($element) {
                return '<div ng-repeat="item in itemsGridCtrl.items" class="sp-grid-item" ' +
                    'ng-class="{ double: itemsGridCtrl.multiply[$index] > 1 }" ' +
                    'ng-style="{ width: (itemsGridCtrl.width * (itemsGridCtrl.multiply[$index] || 1)) + \'%\' }">' + $element.html() + '</div>'
            }
        };
    }])
})(angular);