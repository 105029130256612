(function(angular) {
    'use strict';

    angular.module('spHomePageComponents', ['spApi']);
})(angular);(function(angular) {
    'use strict';

    angular.module('spHomePageComponents')
        .constant('SP_HOME_PAGE_COMPONENT_TYPES', {
            SPECIALS_CAROUSEL: 1,
            PRODUCTS_CAROUSEL: 2,
            LARGE_PRODUCTS_CAROUSEL: 3,
            POSTERS: 4,
            LINKS: 5,
            LINKS_CAROUSEL: 6
        })
        .run(['$rootScope', 'SP_HOME_PAGE_COMPONENT_TYPES', function($rootScope, SP_HOME_PAGE_COMPONENT_TYPES) {
            $rootScope.SP_HOME_PAGE_COMPONENT_TYPES = SP_HOME_PAGE_COMPONENT_TYPES;
        }]);
})(angular);(function(angular) {
    'use strict';

    var CHUNK_SIZE = 4;

    function srv($q, Api, SP_HOME_PAGE_COMPONENT_TYPES) {
        var self = this,
            _total,
            _idsCache = {},
            _cache = [];

        self.getHomePageComponents = getHomePageComponents;
        self.scroll = getScrollInstance;

        self.isSingleItemComponent = isSingleItemComponent;
        self.isCarouselComponent = isCarouselComponent;
        self.isTitleAsItem = isTitleAsItem;
        self.componentProductsGetter = componentProductsGetter;

        function getHomePageComponents(from) {
            from = from || 0;
            var to = from + CHUNK_SIZE;
            if (to <= _cache.length || _total !== undefined && to >= _total && _cache.length === _total) {
                return $q.resolve(_getFromCache(from));
            }

            return Api.request({
                url: '/v2/retailers/:rid/home-page-components',
                method: 'GET',
                params: {
                    isActive: true,
                    isFull: true,
                    from: from,
                    size: CHUNK_SIZE
                }
            }).then(function(resp) {
                _total = resp.total;

                angular.forEach(resp.components, function(component) {
                    if (_idsCache[component.id]) {
                        return;
                    }

                    _idsCache[component.id] = true;
                    _cache.push(component);
                });

                return _getFromCache(from);
            });
        }

        function _getFromCache(from) {
            return _cache.slice(from, from + CHUNK_SIZE);
        }

        function getScrollInstance() {
            return new HomePageComponentsScroll($q, self);
        }

        function isSingleItemComponent(component) {
            return component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL ||
                component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL;
        }

        function isCarouselComponent(component) {
            return component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.SPECIALS_CAROUSEL ||
                component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.PRODUCTS_CAROUSEL ||
                component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LARGE_PRODUCTS_CAROUSEL ||
                component.typeId === SP_HOME_PAGE_COMPONENT_TYPES.LINKS_CAROUSEL;
        }

        function isTitleAsItem(comonent) {
            return isSingleItemComponent(comonent) && comonent.isTitleAsItem;
        }

        function componentProductsGetter(component) {
            return new HomePageComponentProducts(component, $q, Api);
        }
    }

    function HomePageComponentsScroll($q, srv) {
        var self = this,
            _isDone = false,
            _currentIndex = 0,
            _lastPromise = $q.resolve();

        self.next = next;

        function next() {
            var chunkIndex = _currentIndex;
            _currentIndex += CHUNK_SIZE;

            return _lastPromise = _lastPromise.catch(function() {
                // do nothing
            }).then(function() {
                if (_isDone) {
                    return [];
                }

                return srv.getHomePageComponents(chunkIndex);
            }).then(function(chunk) {
                if (!chunk.length) {
                    _isDone = true;
                }

                return chunk;
            });
        }
    }

    function HomePageComponentProducts(component, $q, Api) {
        var self = this,
            _productIds,
            _productFilters,
            _loadedProducts,
            _lastPromise;

        self.get = getProducts;
        self.reset = reset;

        reset();

        function reset() {
            _productIds = (component.productIds || []).concat([]);

            if (component.productsFilters) {
                // try to parse the filters
                try {
                    _productFilters = JSON.parse(component.productsFilters)
                } catch(e) {}
            }

            _lastPromise = $q.resolve();
            _loadedProducts = [];
        }

        function getProducts(from, size, apiFilters) {
            if (_productFilters) {
                return _getByProductFilters(from, size, apiFilters);
            } else {
                return _getByProductIds(from, size, apiFilters);
            }
        }

        function _getByProductFilters(from, size, apiFilters) {
            return Api.request({
                method: 'GET',
                url: '/v2/retailers/:rid/branches/:bid/products',
                params: {
                    from: from,
                    size: size,
                    filters: angular.merge({}, _productFilters.filters || {}, apiFilters || {}),
                    query: _productFilters.query || undefined
                }
            }, {
                fireAndForgot: true
            });
        }

        function _getByProductIds(from, size, apiFilters) {
            _lastPromise = _lastPromise.catch(function() {
                // do nothing
            }).then(function() {
                if ((from + size) <= _loadedProducts.length || !_productIds.length) {
                    return {
                        products: _loadedProducts.slice(from, from + size),
                        total: _loadedProducts.length + _productIds.length
                    };
                }

                var chunkProductIds = _productIds.slice(0, size + 2);
                return Api.request({
                    method: 'GET',
                    url: '/v2/retailers/:rid/branches/:bid/products',
                    params: {
                        from: 0,
                        size: chunkProductIds.length,
                        filters: angular.merge({}, apiFilters || {}, {
                            must: {
                                term: {
                                    id: chunkProductIds
                                }
                            }
                        })
                    }
                }, {
                    fireAndForgot: true
                }).then(function(resp) {
                    var productsMap = {};
                    angular.forEach(resp.products, function(product) {
                        productsMap[product.id] = product;
                    });
                    angular.forEach(chunkProductIds, function(productId) {
                        if (productsMap[productId]) {
                            _loadedProducts.push(productsMap[productId]);
                        }
                    });
                    _productIds.splice(0, chunkProductIds.length);
                });
            });

            return _lastPromise.then(function(resp) {
                if (resp) {
                    return resp;
                } else {
                    return _getByProductIds(from, size, apiFilters);
                }
            });
        }
    }

    angular.module('spHomePageComponents')
        .service('SpHomePageComponents', ['$q', 'Api', 'SP_HOME_PAGE_COMPONENT_TYPES', srv]);
})(angular);