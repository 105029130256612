(function (angular) {
    'use script';

    angular.module('spProductTags', ['spLocalCache'])
        .config(['SpLocalCacheProvider', function(SpLocalCacheProvider) {
            SpLocalCacheProvider.fetchData = [
                'SpProductTags', 'dataIds', 'cacheName', 'PRODUCT_TAGS_CACHE_NAME',
                function(SpProductTags, dataIds, cacheName, PRODUCT_TAGS_CACHE_NAME) {
                    if (cacheName !== PRODUCT_TAGS_CACHE_NAME) {
                        return;
                    }

                    return SpProductTags.getProductTags({
                        from: 0,
                        size: dataIds.length,
                        id: dataIds
                    }).then(function(resp) {
                        return resp.productTags;
                    });
                }
            ];
        }]);
})(angular);(function (angular) {
    'use script';

    angular.module('spProductTags')
        .constant('PRODUCT_TAG_TYPES', {
            SIMPLE: 1,
            LANDING_PAGE: 2,
            EXPRESS_DELIVERY: 3,
            DELIVERY_WITHIN_DAYS: 4,
            EBT_ELIGIBLE: 5,
            EBT_CASH_ELIGIBLE: 6,
            HEAVY_PACKAGE: 7,
            COUPON: 8
        })
        .constant('PRODUCT_TAGS_CACHE_NAME', 'product-tags-cache')
        .run(['$rootScope', 'PRODUCT_TAG_TYPES', 'PRODUCT_TAGS_CACHE_NAME', function($rootScope, PRODUCT_TAG_TYPES, PRODUCT_TAGS_CACHE_NAME) {
            $rootScope.PRODUCT_TAG_TYPES = PRODUCT_TAG_TYPES;
            $rootScope.PRODUCT_TAGS_CACHE_NAME = PRODUCT_TAGS_CACHE_NAME;
        }]);
})(angular);(function (angular) {
    'use strict';

    angular.module('spProductTags').service('SpProductTags', [
        'Api', '$q', 'SpLocalCache', 'PRODUCT_TAGS_CACHE_NAME', 'PRODUCT_TAG_TYPES',
        function(Api, $q, SpLocalCache, PRODUCT_TAGS_CACHE_NAME, PRODUCT_TAG_TYPES) {
            var self = this,
                retailerIdDefer,
                retailerIdPromiseFulfilled = false,
                _deliveryWithinTagPromise;

            self.setRetailerId = setRetailerId;
            self.getRetailerId = getRetailerId;
            self.getProductTags = getProductTags;
            self.getDeliveryWithinDaysTag = getDeliveryWithinDaysTag;
            self.getLocalProductTag = getLocalProductTag;
            self.setLocalProductTag = setLocalProductTag;
            self.resetProductTagsCache = resetProductTagsCache;
            self.isProductTagRelationActive = isProductTagRelationActive;

            /**
             * Saves the retailer id on the service to be able to GET product tags
             * @public
             *
             * @param {number} retailerId
             *
             * @returns {Promise}
             */
            function setRetailerId(retailerId) {
                // when resetting the retailer id the retailer id promise should be reset
                if (retailerId === undefined) {
                    _resetRetailerIdPromise();
                    return $q.resolve();
                }

                return _resolveRetailerId(retailerId);
            }

            /**
             * Returns a promise which will be resolved when the retailer id will be set
             * @public
             *
             * @returns {Promise<number>}
             */
            function getRetailerId() {
                return _getRetailerIdDefer().promise;
            }

            /**
             * Returns a deferred promise for the retailer id
             * @private
             *
             * @returns {Deferred}
             */
            function _getRetailerIdDefer() {
                if (!retailerIdDefer) {
                    retailerIdDefer = $q.defer();
                }

                return retailerIdDefer;
            }

            /**
             * Resets the retailer id promise when it was fulfilled
             * @private
             */
            function _resetRetailerIdPromise() {
                if (retailerIdPromiseFulfilled) {
                    retailerIdPromiseFulfilled = false;
                    retailerIdDefer = undefined;
                    resetProductTagsCache();
                }
            }

            /**
             * Resolves the retailer id defer with the given retailer id
             * @private
             *
             * @param {number} retailerId
             *
             * @returns {Promise}
             */
            function _resolveRetailerId(retailerId) {
                return $q.resolve().then(function() {
                    if (!retailerIdPromiseFulfilled) {
                        return;
                    }

                    return _getRetailerIdDefer().promise;
                }).then(function(savedRetailerId) {
                    // reset the promise for the new retailer id to be returned from the promise in the future
                    if (savedRetailerId && savedRetailerId !== retailerId) {
                        _resetRetailerIdPromise();
                    }

                    retailerIdPromiseFulfilled = true;
                    _getRetailerIdDefer().resolve(retailerId);
                });
            }

            /**
             * Get product tags
             * @public
             *
             * @param {Object} params
             *
             * @returns {Promise<Object>}
             */
            function getProductTags(params) {
                return getRetailerId().then(function(retailerId) {
                    return Api.request({
                        method: 'GET',
                        url: '/v2/retailers/' + retailerId + '/product-tags',
                        params: params
                    }).then(function(res) {
                        angular.forEach(res.productTags, function(productTag) {
                            setLocalProductTag(productTag.id, productTag);
                        });

                        return res;
                    });
                });
            }

            /**
             * Will return the first product tag with delivery within days type
             * @public
             *
             * @return {Promise<Object>}
             */
            function getDeliveryWithinDaysTag() {
                if (_deliveryWithinTagPromise) {
                    return _deliveryWithinTagPromise;
                }

                return _deliveryWithinTagPromise = getProductTags({
                    from: 0,
                    size: 1,
                    productTagTypeId: PRODUCT_TAG_TYPES.DELIVERY_WITHIN_DAYS
                }).then(function(resp) {
                    return resp.productTags[0];
                }).catch(function() {
                    _deliveryWithinTagPromise = undefined;
                });
            }

            /**
             * Returns the local product tag from the local product tags cache
             * @public
             *
             * @param {number} productTagId
             * @param {Object} options
             *
             * @returns {Object}
             */
            function getLocalProductTag(productTagId, options) {
                return SpLocalCache.getLocalData(productTagId, PRODUCT_TAGS_CACHE_NAME, options)
            }

            /**
             * Sets a product tag to the local product tags cache
             * @public
             *
             * @param {number} productTagId
             * @param {Object} productTag
             */
            function setLocalProductTag(productTagId, productTag) {
                return SpLocalCache.setLocalData(productTagId, PRODUCT_TAGS_CACHE_NAME, productTag)
            }

            /**
             * Empties the product tags local cache
             * @public
             */
            function resetProductTagsCache() {
                SpLocalCache.resetLocalCache(PRODUCT_TAGS_CACHE_NAME);
            }

            /**
             * Returns whether a product tag data item on a product is active or not
             * @public
             *
             * @param {object} productTagRelation
             *
             * @returns {boolean}
             */
            function isProductTagRelationActive(productTagRelation) {
                return productTagRelation.isActive && (
                    productTagRelation.isRetailerRelation || productTagRelation.isGS1Relation ||
                    !productTagRelation.isGlobalRelation || !productTagRelation.isExcluded
                );
            }
        }
    ]);
})(angular);(function (angular) {
    'use strict';

    angular.module('spProductTags').filter('productTag', [
        '$filter', 'PRODUCT_TAGS_CACHE_NAME',
        function ($filter, PRODUCT_TAGS_CACHE_NAME) {
            return function (productTagId) {
                return $filter('spLocalCache')(productTagId, PRODUCT_TAGS_CACHE_NAME);
            };
        }
    ]);
})(angular);(function (angular) {
    'use strict';

    var SEARCH_IN_CHUNK_SIZE = 5;

    angular.module('spProductTags').filter('productMainTag', [
        '$filter', '$q', 'SpProductTags', 'PRODUCT_TAG_TYPES', 'PRODUCT_TAGS_CACHE_NAME',
        function ($filter, $q, SpProductTags, PRODUCT_TAG_TYPES, PRODUCT_TAGS_CACHE_NAME) {
            var tagIdByProductId = {};

            function _setProductMainTag(product, boostTags, mapKey) {
                if (!product.productTagsData) {
                    return $q.resolve();
                }

                var boostTagsMap = {};
                angular.forEach(boostTags, function(tagId) {
                    boostTagsMap[tagId] = true;
                });

                var productBoostedTags = [],
                    productAllTags = [];
                angular.forEach(product.productTagsData, function(productTag) {
                    if (!SpProductTags.isProductTagRelationActive(productTag) ||
                        productTag.showIconOnProductPageOnly ||
                        productTag.typeId === PRODUCT_TAG_TYPES.LANDING_PAGE) {
                        return;
                    }

                    if (boostTagsMap[productTag.id]) {
                        productBoostedTags.push(productTag.id);
                    } else {
                        productAllTags.push(productTag.id);
                    }
                });

                return _findMainTag(productBoostedTags.concat(productAllTags)).then(function(mainProductTag) {
                    if (mainProductTag) {
                        tagIdByProductId[mapKey] = mainProductTag.id;
                    }
                });
            }

            function _findMainTag(productTagIds) {
                if (!productTagIds.length) {
                    return $q.resolve();
                }

                // prepare promises for the next chunk to search in
                var promises = [];
                angular.forEach(productTagIds.splice(0, SEARCH_IN_CHUNK_SIZE), function(productTagId) {
                    promises.push(SpProductTags.getLocalProductTag(productTagId));
                });

                return $q.all(promises).then(function(productTags) {
                    // return the first product tag with an icon
                    for (var i = 0; i < productTags.length; i++) {
                        if (productTags[i] && _productTagHasAnIcon(productTags[i])) {
                            return productTags[i];
                        }
                    }

                    // if not found look for another one in the next chunk
                    return _findMainTag(productTagIds);
                });
            }

            function _productTagHasAnIcon(productTag) {
                var languageKeys = Object.keys(productTag.languages);
                for (var i = 0; i < languageKeys.length; i++) {
                    if (productTag.languages[languageKeys[i]].iconResourceUrl) {
                        return true;
                    }
                }
                return false;
            }

            function _getMapKey(product, boostTags) {
                var arr = [product.id];
                angular.forEach(boostTags, function(boostTag) {
                    if (boostTag) {
                        arr.push(boostTag);
                    }
                });
                return arr.join('-');
            }

            return function (product, boostTags) {
                var key = _getMapKey(product, boostTags);

                if (!tagIdByProductId.hasOwnProperty(key)) {
                    tagIdByProductId[key] = undefined;
                    _setProductMainTag(product, boostTags, key);
                    return;
                }

                if (tagIdByProductId[key]) {
                    return $filter('spLocalCache')(tagIdByProductId[key], PRODUCT_TAGS_CACHE_NAME);
                }
            };
        }
    ]);
})(angular);