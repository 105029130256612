(function (angular) {
    'use script';

    angular.module('spLocalCache', []);
})(angular);(function (angular) {
    'use script';

    var DEFAULT_CACHE_NAME = 'sp-local-cache-default',
        PRODUCT_TAGS_SIZE = 100;

    angular.module('spLocalCache').config(['$provide', function($provide) {
        var provider = $provide.service('SpLocalCache', ['$rootScope', '$q', '$timeout', '$injector', SpLocalCache]);

        provider.fetchData = _defaultFetchData;

        function _defaultFetchData() {
            throw new Error('SpLocalCacheProvider.fetchData must implemented (read the readme.md for more info).');
        }

        function SpLocalCache($rootScope, $q, $timeout, $injector) {
            var self = this,
                _localScope = $rootScope.$new(),
                _locallySavedData = {},
                _localDataToFetch = {},
                _fetchCacheTimeouts = {};

            self.getLocalData = getLocalData;
            self.setLocalData = setLocalData;
            self.resetLocalCache = resetLocalCache;

            /**
             * @typedef {Object} SpGetLocalDataOptions
             *
             * @property {boolean} [doNotReturnPromise]
             * @property {boolean} [doNotFetchData]
             */

            /**
             * Get a local data of a given id
             * @public
             *
             * @param {string|number} dataId
             * @param {string|SpGetLocalDataOptions} [cacheNameOrOptions] - can represent options when no cache name
             * @param {SpGetLocalDataOptions} [options]
             *
             * @returns {Promise<*>|*}
             */
            function getLocalData(dataId, cacheNameOrOptions, options) {
                var cacheName = DEFAULT_CACHE_NAME;
                if (options === undefined && typeof(cacheNameOrOptions) !== 'string') {
                    options = cacheNameOrOptions;
                } else if (cacheNameOrOptions !== undefined) {
                    cacheName = cacheNameOrOptions;
                }
                options = options || {};

                if (!dataId) {
                    if (options.doNotReturnPromise) {
                        return;
                    } else {
                        return $q.resolve();
                    }
                }

                var savedData = _getSavedData(dataId, cacheName);
                if (savedData || savedData === null || options.doNotFetchData) {
                    if (options.doNotReturnPromise) {
                        return savedData;
                    } else {
                        return $q.resolve(savedData);
                    }
                }

                _addIdToFetch(dataId, cacheName);
                setLocalData(dataId, cacheName, new $q(function(resolve) {
                    var listener = _localScope.$on('localFetched', function() {
                        var savedData = _getSavedData(dataId, cacheName);
                        if ((savedData || savedData === null) && (!savedData || !savedData.then || !angular.isFunction(savedData.then))) {
                            resolve(savedData);
                            listener();
                        }
                    });
                }));
                _fetchLocalCacheTimeout(cacheName);
                return _getSavedData(dataId, cacheName);
            }

            /**
             * Set data into a data id in a local cache
             * @public
             *
             * @param {string|number} dataId
             * @param {string|*} cacheNameOrData
             * @param {*} [data]
             */
            function setLocalData(dataId, cacheNameOrData, data) {
                var cacheName;
                if (data === undefined) {
                    data = cacheNameOrData;
                } else {
                    cacheName = cacheNameOrData;
                }

                cacheName = cacheName || DEFAULT_CACHE_NAME;
                _locallySavedData[cacheName] = _locallySavedData[cacheName] || {};
                _locallySavedData[cacheName][dataId] = data;
            }

            /**
             * Empty the local cache of a cache name
             * @public
             *
             * @param {string} [cacheName]
             */
            function resetLocalCache(cacheName) {
                cacheName = cacheName || DEFAULT_CACHE_NAME;
                if (!_locallySavedData[cacheName]) {
                    return;
                }

                angular.forEach(_locallySavedData[cacheName], function(dataItem, key) {
                    if (!dataItem || !dataItem.then || !angular.isFunction(dataItem.then)) {
                        delete _locallySavedData[cacheName][key];
                    }
                });
            }

            function _addIdToFetch(dataId, cacheName) {
                _localDataToFetch[cacheName] = _localDataToFetch[cacheName] || [];
                _localDataToFetch[cacheName].push(dataId);
            }

            function _getSavedData(dataId, cacheName) {
                return _locallySavedData[cacheName] && _locallySavedData[cacheName][dataId];
            }

            function _fetchLocalCacheTimeout(cacheName) {
                _cancelCacheTimeout(cacheName);
                _fetchCacheTimeouts[cacheName] = $timeout(function() {
                    return _fetchLocalCache(cacheName);
                }, 200);
            }

            function _cancelCacheTimeout(cacheName) {
                if (!_fetchCacheTimeouts[cacheName]) {
                    return;
                }

                $timeout.cancel(_fetchCacheTimeouts[cacheName]);
                delete _fetchCacheTimeouts[cacheName];
            }

            function _fetchLocalCache(cacheName) {
                _cancelCacheTimeout(cacheName);

                if (!_localDataToFetch[cacheName]) {
                    return;
                }

                var toFetch = _localDataToFetch[cacheName].splice(0, PRODUCT_TAGS_SIZE);
                return $injector.invoke(provider.fetchData, provider, {
                    dataIds: toFetch,
                    cacheName: cacheName
                }).then(function(data) {
                    var dataMap = {};
                    angular.forEach(data, function(dataItem) {
                        dataMap[dataItem.id] = dataItem;
                    });
                    angular.forEach(toFetch, function(id) {
                        setLocalData(id, cacheName, dataMap[id] || null);
                    });
                    _localScope.$emit('localFetched', data);

                    if (_localDataToFetch[cacheName].length && !_fetchCacheTimeouts[cacheName]) {
                        return _fetchLocalCache(cacheName);
                    }
                });
            }
        }
    }]);
})(angular);(function (angular, app) {
    'use strict';

    angular.module('spLocalCache').filter('spLocalCache', ['SpLocalCache', SpLocalCacheFilter]);

    function SpLocalCacheFilter(SpLocalCache) {
        return function(dataId, cacheName) {
            var data = SpLocalCache.getLocalData(dataId, cacheName, {
                doNotReturnPromise: true
            });

            if (!data || data.then && angular.isFunction(data.then)) {
                return;
            }

            return data;
        };
    }
})(angular);
