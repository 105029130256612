(function (window) {
    'use strict';

    window.spBuildCss = spBuildCss;

    /**
     * Change your css by your theme config, and add it to your html
     * @public
     *
     * @param {String} cssStr
     * @param {Object} themeConfig
     * @param {HTMLStyleElement} element
     */
    function spBuildCss(cssStr, themeConfig, element) {
        cssStr = cssStr.replace(/'?"?\{\{([a-zA-Z0-9]+)-?([a-zA-Z0-9#]+)?-?([0-9]+)?\}\}'?"?/g, function (originalValue, key, mixColorOrAlpha, weight) {
            var value = themeConfig[key];
            if (mixColorOrAlpha && weight) {
                return _mix(value, mixColorOrAlpha, weight);
            } else if (mixColorOrAlpha) {
                return _hexToRgba(value, mixColorOrAlpha);
            }

            return value;
        });

        if (element.styleSheet) {
            element.styleSheet.cssText = cssStr;
        } else {
            element.appendChild(document.createTextNode(cssStr));
        }
    }

    /**
     * decimal to hexadecimal
     * @private
     * 
     * @param d
     * @returns {string}
     */
    function _d2h(d) {
        return d.toString(16);
    }

    /**
     *hexadecimal to decimal
     * @private
     * @param h
     * @returns {Number}
     */
    function _h2d(h) {
        return parseInt(h, 16);
    }

    /**
     * Mix
     * @private
     *
     * @param color1
     * @param color2
     * @param weight
     *
     * @returns {string}
     */
    function _mix(color1, color2, weight) {
        weight = Number(weight) || 50;
        color1 = color1[0] == '#' ? color1.substring(1) : color1;
        color2 = color2[0] == '#' ? color2.substring(1) : color2;

        var color = '#';
        for (var i = 0; i <= 5; i += 2) {
            var v1 = _h2d(color1.substr(i, 2)),
                v2 = _h2d(color2.substr(i, 2)),
                val = _d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

            while (val.length < 2) {
                val = '0' + val;
            }

            color += val;
        }

        return color;
    }

    /**
     * Hex to rgba
     * @private
     *
     * @param hex
     * @param alpha
     *
     * @returns {string}
     */
    function _hexToRgba(hex, alpha) {
        return 'rgba(' + parseInt(hex.substring(1, 3), 16) + ',' + parseInt(hex.substring(3, 5), 16) + ',' + parseInt(hex.substring(5, 7), 16) + ',' + (alpha / 100) + ')';
    }
})(window);