(function() {
    'use strict';

    angular.module('spInViewWatcher', []).directive('spInViewWatcher', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
        var windowElement = angular.element(window);

        function isVisible(el, parent, threshold) {
            var inRect = _getParentRect(parent, threshold),
                rect = el[0].getBoundingClientRect(),
                inView = (rect.top >= inRect.top && rect.top <= inRect.bottom
                    || rect.bottom >= inRect.top && rect.bottom <= inRect.bottom)
                    &&
                    (rect.left >= inRect.left && rect.left <= inRect.right
                        || rect.right >= inRect.left && rect.right <= inRect.right);

            //Remove this to Test if elements is in view
            // setTimeout(function() {
            //     el[0].style.backgroundColor = inView ? 'yellow' : '';
            // }, 0);

            if (inView) {
                el.removeClass('sp-not-in-view');
            } else {
                el.addClass('sp-not-in-view');
            }

            return inView;
        }

        function _getParentRect(parent, threshold) {
            var $parent = angular.element(parent),
                parentRect = $parent[0] === window.document ? {top: 0, left: 0, bottom: window.innerHeight, right: window.innerWidth} : $parent[0].getBoundingClientRect();

            if(threshold) {
                parentRect.top -= threshold;
                parentRect.left -= threshold;
                parentRect.bottom += threshold;
                parentRect.right += threshold;
            }

            return parentRect;
        }

        function _getElementCssProp(element, prop) {
            element = angular.element(element)[0];
            if (element === document) {
                element = document.scrollingElement;
            }
            var res = '',
                propWithUpperCase = prop.replace(/\-.{0,1}/g, function (foundStr) {
                    return foundStr[1].toUpperCase();
                });
            if (angular.isFunction(window.getComputedStyle)) {
                res = window.getComputedStyle(element).getPropertyValue(prop);
            } else if (element.currentStyle && element.currentStyle[propWithUpperCase]) {
                res = element.currentStyle[propWithUpperCase];
            }
            return res;
        }

        function _isScrollElement(element) {
            return /(auto|scroll)/.test(_getElementCssProp(element, 'overflow') + _getElementCssProp(element, 'overflow-y') + _getElementCssProp(element, 'overflow-x'));
        }

        function getScrollParent(element) {
            element = angular.element(element)[0];
            var position = _getElementCssProp(element, 'position'),
                excludeStaticParent = position === 'absolute';

            var parent = element,
                isScrollElement;
            do {
                parent = parent.parentElement || parent.parentNode;
                isScrollElement = parent && (!excludeStaticParent || _getElementCssProp(element, 'position') !== 'static') && _isScrollElement(parent, excludeStaticParent);
            } while (parent && !isScrollElement);

            if (parent && parent.tagName.toUpperCase() === 'HTML') {
                parent = null;
            }

            if (element.getAttribute('fixedScrollParent')) {
                return parent || element.parentElement || element.parentNode;
            }

            return (position === 'fixed' || !parent) ? element.ownerDocument || document : parent;
        }

        function watchDuringDisable() {
            this.$$watchersCopy = this.$$watchersCopy || [];
            this.$$watchers = this.$$watchersCopy;
            var unwatch = this.constructor.prototype.$watch.apply(this, arguments);
            this.$$watchers = null;
            return unwatch;
        }

        function toggleWatchers($scope, enable) {
            var digest, current, next = $scope;
            do {
                current = next;
                if (enable) {
                    if (current.hasOwnProperty("$$watchersCopy")) {
                        current.$$watchers = current.$$watchersCopy;
                        delete current.$$watchersCopy;
                        delete current.$watch;
                        digest = $scope.$root && !$scope.$root.$$phase;
                    }
                } else {
                    if (!current.hasOwnProperty("$$watchersCopy")) {
                        current.$$watchersCopy = current.$$watchers;
                        current.$$watchers = null;
                        current.$watch = watchDuringDisable;
                    }
                }
                next = current.$$childHead;
                while (!next && current !== $scope) {
                    if (current.$$nextSibling) {
                        next = current.$$nextSibling;
                    } else {
                        current = current.$parent;
                    }
                }
            } while (next);
            if (digest) {
                $scope.$digest();
            }
        }

        return {
            link: function ($scope, $element, $attrs) {

                var _timer,
                    _resizeCompleteTimeout,
                    _listeners = [],
                    _positionWatcher,
                    _positionWatcherTimeout,
                    $parent = getScrollParent($element),
                    _lastOffsetTop = $element[0].offsetTop,
                    _lastOffsetLeft = $element[0].offsetLeft;

                //to prevent disabling watchers before content calculated on first shown items
                var _expectedShownItems = Number($attrs.spInViewWatcher);
                if (!isNaN(_expectedShownItems) && $scope.$index < _expectedShownItems) {
                    _timer = $timeout(function () {
                        if (!isVisible($element, $parent, _getThreshold())) {
                            toggleWatchers($scope, false);
                        }
                    });
                } else {
                    if (!isVisible($element, $parent, _getThreshold())) {
                        toggleWatchers($scope, false);
                    }
                }

                _watchPosition();

                function updateWatchers() {
                    toggleWatchers($scope, isVisible($element, $parent, _getThreshold()));
                }

                function _getThreshold() {
                    return $scope.$eval($attrs.threshold) || 0;
                }

                /*function onResize() {
                    if (_resizeCompleteTimeout) {
                        $timeout.cancel(_resizeCompleteTimeout);
                    } else {
                        toggleWatchers($scope, true);
                    }

                    _resizeCompleteTimeout = $timeout(function() {
                        toggleWatchers($scope, isVisible($element, $parent, _getThreshold()));
                    }, 50);
                }*/

                angular.element($parent).bind('scroll', updateWatchers);
                // windowElement.bind('resize', onResize);

                //listen for a change in the element's position
                function _watchPosition() {
                    $timeout(function () {
                        _positionWatcher = $rootScope.$watchGroup([function() {
                            return $element[0].offsetTop;
                        }, function() {
                            return $element[0].offsetLeft;
                        }], function(newValues) {
                            if (newValues[0] !== _lastOffsetTop || newValues[1] !== _lastOffsetLeft) {
                                _lastOffsetTop = newValues[0];
                                _lastOffsetLeft = newValues[1];

                                updateWatchers();
                                _positionWatcher();
                                _positionWatcher = null;
                                _watchPosition();
                            }
                        });
                    }, 150);
                }

                if ($attrs.spInViewWatcherRefreshOn) {
                    var events = $scope.$eval($attrs.spInViewWatcherRefreshOn);
                    if (events) {
                        events = angular.isArray(events) ? events : [events];
                        for (var i = 0; i < events.length; i++) {
                            _listeners.push($scope.$root.$on(events[i], function() {
                                $timeout(updateWatchers);
                            }));
                        }
                    }
                }

                $scope.$on('$destroy', function () {
                    angular.element($parent).unbind('scroll', updateWatchers);
                    // windowElement.unbind('resize', onResize);
                    _timer && $timeout.cancel(_timer);
                    _positionWatcher && _positionWatcher();
                    _positionWatcherTimeout && $timeout.cancel(_positionWatcherTimeout);
                    for (var i = 0; i < _listeners.length; i++) {
                        _listeners[i]();
                    }
                });
            }
        };
    }]);
})();